.carousel-control-prev,
.carousel-control-next {
  display: none !important;
}

.icons-list div {
  line-height: 40px;
  white-space: nowrap;
  cursor: default;
  position: relative;
  z-index: 1;
  padding: 5px;
  border-right: 1px solid #252a33;
}
.icons-list div i {
  display: inline-block;
  width: 40px;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  -webkit-transition: font-size 0.2s;
  -moz-transition: font-size 0.2s;
  transition: font-size 0.2s;
}
.icons-list div:hover i {
  font-size: 26px;
}

.form_wizard_tab {
  background-color: #6f7b7d;
  border-radius: 0.1875rem;
  color: #fff;
  margin-right: 2px;
}

.form_wizard_tab:hover {
  background-color: #6f7b7d;
  border-radius: 0.1875rem;
  color: #fff;
  margin-right: 2px;
}

.form_wizard_tab.active {
  background-color: #17c2d7 !important;
  border-color: transparent !important;
  color: #fff !important;
}

.form_wizard_width {
  width: calc(25% - 2px);
}

.lookalike .form_wizard_width {
  width: calc(50% - 2px);
}

.lookalike a {
  text-align: center;
}

.form_wizard_width1 {
  width: calc(33% - 2px);
}

.left_dropdown_btn {
  background-color: transparent !important;
  border: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.left_dropdown_btn:hover {
  background-color: transparent;
  border: 0 !important;
  box-shadow: unset !important;
  outline: 0 !important;
}

.left_dropdown_btn:active {
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: unset !important;
  outline: 0 !important;
}

.left_dropdown_btn:focus {
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: unset !important;
  outline: 0 !important;
}

.user-account .dropdown .dropdown-menu {
  top: 0 !important;
}

.sidebar-scroll {
  overflow-y: auto;
  overflow-x: scroll;
  -ms-overflow-style: none;
  height: 100%;
}

.sidebar-scroll::-webkit-scrollbar {
  width: 0;
  background-color: #282b2f;
}

.sidebar-scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #282b2f;
}

.black-editor {
  background-color: #282b2f !important;
}

.text-editor-black .public-DraftEditor-content {
  height: 150px;
  border: 1px solid #f1f1f1;
  padding: 5px;
}

.custom_tab.active {
  background-color: #2f3338 !important;
  border-color: #2f3338 !important;
  color: #a5a8ad !important;
}

.custom_tab.nav-link {
  color: #ffffff;
}

.mini_sidebar .mini_sidebar_on .user-account {
  display: none !important;
}

.custom_pagination > * {
  margin: 10px;
}

.custom_pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiButtonBase-root.MuiPaginationItem-root {
  background-color: #ffffff;
}

.VulnerabilitiesBox .row .col-lg-6,
.VulnerabilitiesBox .row .col-md-6 {
  padding-right: 10px;
  padding-left: 10px;
}

.bg-critical {
  background-color: #f66d9b !important;
}
.bg-high {
  background-color: #6435c9 !important;
}
.bg-medium {
  background-color: #9367b4 !important;
}
.bg-low {
  background-color: #5e6266 !important;
}

.vulerabilityHeader {
  border-radius: 3px;
  font-weight: 400;
  color: #a5a8ad;
  background: #282b2f;
  padding: 12px 12px 4px 12px;
}

.vulerabilityHeader .card {
  margin-bottom: 24px;
}

.assetsDiscoveryModal .modal-dialog {
  overflow-y: initial !important;
}
.assetsDiscoveryModal .modal-body,
#webTech .modal-body,
#clientModal .modal-body {
  height: 80vh;
  overflow-y: auto;
}

.badge-low {
  background: transparent;
  border-color: green;
  color: green;
}

.badge-med {
  background: transparent;
  border-color: yellow;
  color: yellow;
}

.badge-high {
  background: transparent;
  border-color: orange;
  color: orange;
}

.badge-critical {
  background: transparent;
  border-color: red;
  color: red;
}

.toggle-password {
  position: absolute;
  right: 17px;
  /* top: 30%; */
  transform: translateY(-50%);
  color: #757575;
  cursor: pointer;
}
.toggle-password.fa-eye {
  margin-right: 1px;
}

ul.metismenu {
  padding-bottom: 46px !important;
}

#tags-standard {
  color: #ffffff;
}

.disabledClass {
  pointer-events: none;
  cursor: default;
}

.reactTooltipClass {
  width: 250px;
  word-break: break-all;
  overflow-wrap: break-word;
}

#summaryBox .col-6,
#summaryBox2 .col-sm-6 {
  padding-right: 10px;
  padding-left: 10px;
}

#summaryBox .body {
  max-height: 158px;
}

#summaryBox2 .card .body {
  /* margin-right: 1%; */
  min-height: 184px;
}

.clientCalendar .react-datepicker-wrapper {
  width: 100%;
}

.clientPageModal {
  min-width: 1000px;
}

.vendorModal {
  min-width: 400px;
  max-width: 600px;
}

.vendorMainModal {
  height: 70%;
  top: 10%;
}

#clientServices .MuiInput-underline:before {
  border-bottom: 1px solid #393d42;
}

.home_screen {
  background-image: url("../images/image-gallery/background_1.png");
  background-size: cover;
  overflow: hidden;
}

.home_screen .text-muted1 {
  font-size: 10px;
}

.tiles_text {
  font-size: 14px;
}

.home_screen p {
  margin-bottom: 8px;
  line-height: 13px;
}

.home_screen .m-t-20 {
  margin-top: 10px;
}

.headerTop {
  height: 108px;
  background-color: #141821;
  margin: 0px;
  width: 100%;
}

.home_icon {
  background-color: #2d3240;
  /* background-image: linear-gradient(#2F3443, #2A2F3D, #1E222E, #141821); */
}

.logoImage {
  margin-left: 30%;
  margin-top: 20%;
}

.home_icon_text {
  font-size: 14px;
  text-align: left;
  display: block;
  margin-top: 6%;
  padding-left: 14px;
  margin-bottom: 6%;
  color: #ffffff;
}

.home_fa_icon {
  font-size: 30px;
  text-align: center;
  border: 1px solid;
  margin-left: 22%;
  padding: 14px 12px;
  border-radius: 100%;
}

.breach-detection-box {
  width: 60px;
  height: 56px;
  display: block;
  background: #000000;
  border-radius: 100%;
  margin-left: 22%;
  margin-top: 10px;
}

.assesment_mode span {
  border-bottom: 6px solid red;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.report_mode span {
  border-bottom: 6px solid green;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.setting_mode span {
  border-bottom: 6px solid orange;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.dashboard_box {
  width: 10%;
  float: left;
  margin-left: 1%;
}

.dashboard_box_second {
  width: 15%;
  float: left;
  margin-left: 1%;
}

.dashboard_box_second .hoverClass:hover {
  border: 1px solid skyblue;
  display: block;
}

.dashboard_box_second .hoverClass:hover + .dashboard_circle_second {
  border: 1px solid skyblue;
  background: skyblue;
}

.dashboard_box .hoverClass:hover {
  border: 1px solid red;
  display: block;
}

.dashboard_box .hoverClass:hover + .dashboard_circle {
  border: 1px solid red;
  background: red;
}

.dashboard_tiles {
  border-radius: 6px;
  font-weight: 400;
  color: #ffffff;
  /* background: #282b2f; */
  background-image: linear-gradient(#2f3443, #2a2f3d, #1e222e, #141821);
  padding: 20px 20px 2px 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.09);
  box-shadow: 15px 15px 50px #0b0b0bb5;
}

.loginBg {
  background-image: linear-gradient(#2f3443, #2a2f3d, #1e222e, #141821) !important;
}

.modal-dialog .modal-content {
  background-image: linear-gradient(#2f3443, #2a2f3d, #1e222e, #141821) !important;
}

.login_container {
  display: flex;
  height: calc(100vh + 100px);
  /* height: 100vh; */
  background-image: url("../images/image-gallery/background_image.jpg");
}

.dashboard_circle {
  border: 1px solid black;
  margin: auto;
  height: 20px;
  width: 22px;
  border-radius: 80%;
  background: black;
  margin-top: 20px;
}

.dashboard_circle_second {
  border: 1px solid black;
  margin: auto;
  height: 20px;
  width: 22px;
  border-radius: 80%;
  background: black;
  margin-top: 20px;
}

.no_margin {
  margin: 0px;
  padding: 0px;
}

.guage_body {
  border-radius: 3px;
  font-weight: 400;
  color: #ffffff;
  background: transparent;
  /* padding: 22px; */
}

.fa-dns {
  background-image: url("../images/image-gallery/dns.svg");
  width: 32px;
  height: 32px;
}

.fa-vuln {
  background-image: url("../images/image-gallery/SlideCard_Icon-6.svg");
  width: 28px;
  height: 32px;
}

.fa-ssl {
  background-image: url("../images/image-gallery/SlideCard_Icon-2.svg");
  width: 32px;
  height: 32px;
}

.fa-source {
  background-image: url("../images/image-gallery/SlideCard_Icon.svg");
  width: 32px;
  height: 32px;
}

.fa-assets {
  background-image: url("../images/image-gallery/SlideCard_Icon-1.svg");
  width: 32px;
  height: 32px;
}

.fa-stolen {
  background-image: url("../images/image-gallery/SlideCard_Icon-3.svg");
  width: 32px;
  height: 32px;
}

.fa-blacklisted {
  background-image: url("../images/image-gallery/SlideCard_Icon-4.svg");
  width: 21px;
  height: 32px;
}

.fa-fake-mobile {
  background-image: url("../images/image-gallery/SlideCard_Icon-5.svg");
  width: 32px;
  height: 32px;
}

.fa-social {
  background-image: url("../images/image-gallery/SlideCard_Icon-7.svg");
  width: 32px;
  height: 32px;
}

.fa-hosts {
  background-image: url("../images/image-gallery/host_discovered.svg");
  width: 32px;
  height: 31px;
}

.fa-location {
  background-image: url("../images/image-gallery/host_locations.svg");
  width: 32px;
  height: 21px;
}

.fa-cloud-provider {
  background-image: url("../images/image-gallery/cloud_provider.svg");
  width: 32px;
  height: 32px;
}

.fa-open-port {
  background-image: url("../images/image-gallery/open_ports.svg");
  width: 20px;
  height: 32px;
}

.fa-operating-system {
  background-image: url("../images/image-gallery/operating_systems.svg");
  width: 32px;
  height: 32px;
}

.fa-network-service {
  background-image: url("../images/image-gallery/network_services.svg");
  width: 32px;
  height: 31px;
}

.detail_screen {
  /* background-image: url("../images/image-gallery/without_log.png"); */
  background-size: cover;
  height: auto;
  min-height: 100vh;
  overflow: auto;
}

.detail_screen .headerBox {
  width: 7%;
}

.sidemenu_bar {
  position: fixed;
  top: 20%;
  z-index: 1;
}

.sidemenu_bg {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.sidemenu_bar p {
  margin-bottom: 0px;
  font-size: 12px;
  line-height: 13px;
  color: #ffffff;
}

.sidemenu_area {
  text-align: center;
}

.left_align {
  text-align: left;
}

.sidemenu_top_category {
  border-bottom: 1px solid #6b6666;
  padding: 10px 6px;
  border-top: 1px solid #6b6666;
  border-right: 1px solid #6b6666;
  border-top-right-radius: 18px;
}

.sidemenu_bottom_category {
  padding: 10px 6px;
  border-bottom: 1px solid #6b6666;
}

.sidemenu_item {
  border-bottom: 1px solid #6b6666;
  padding: 10px 12px;
  background-color: #141821;
}

.breach_top_box {
  min-height: 31px;
  width: auto;
  padding: 10px;
  border-top: 2px solid #6b6666;
  border-right: 2px solid #6b6666;
  border-top-right-radius: 40px;
}

.breach_top_image {
  background-image: url("../images/image-gallery/breach.svg");
  display: block;
  width: 32px;
  height: 31px;
  margin-left: 24%;
}

.fa-stolen-cred {
  background-image: url("../images/image-gallery/stolen_credentials.svg");
  width: 24px;
  height: 24px;
}

.fa-malware-infected {
  background-image: url("../images/image-gallery/malware_infected_computers.svg");
  width: 32px;
  height: 29px;
}

.fa-source-code {
  background-image: url("../images/image-gallery/source_code_leakage.svg");
  width: 24px;
  height: 24px;
}

.fa-leaked-session {
  background-image: url("../images/image-gallery/leaked_session.svg");
  width: 32px;
  height: 38px;
}

.fa-open-cloud {
  background-image: url("../images/image-gallery/open_cloud_buckets.svg");
  width: 15px;
  height: 24px;
}

.fa-ip-blacklist {
  background-image: url("../images/image-gallery/ip_blacklist.svg");
  width: 24px;
  height: 23px;
}

.fa-dashboard-icon {
  background-image: url("../images/image-gallery/dashboard.svg");
  width: 33px;
  height: 30px;
  margin-left: 22%;
  margin-top: 12px;
}

.fa-security {
  background-image: url("../images/image-gallery/security_health.svg");
  width: 32px;
  height: 32px;
  margin-left: 22%;
  margin-top: 12px;
}

.fa-brand {
  background-image: url("../images/image-gallery/brand_security.svg");
  width: 32px;
  height: 32px;
  margin-left: 22%;
  margin-top: 12px;
}

.gradinet-effect {
  background-image: linear-gradient(#2f3443, #2a2f3d, #1e222e, #141821);
  box-shadow: 15px 15px 50px #0b0b0bb5;
  border-radius: 10px;
}

.table-box {
  margin-top: 10px;
}

.table-box .table thead th {
  background: #141821;
}

.table-box {
  height: 350px;
  overflow: scroll;
  overflow-x: hidden;
  position: relative;
  border-collapse: collapse;
}

.table-box th {
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.table-box .table-custom tbody tr {
  background: none;
}

.table-box::-webkit-scrollbar {
  width: 6px; /* width of the entire scrollbar */
}

.table-box::-webkit-scrollbar-track {
  background: #ffffff; /* color of the tracking area */
}

.table-box::-webkit-scrollbar-thumb {
  background-color: #8a8888; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 1px solid #ffffff; /* creates padding around scroll thumb */
}

#main-content {
  /* transition: all 0.3s ease-in-out; */
  width: 100%;
  float: none;
  position: relative;
  margin-top: 30px;
  padding: 0px;
}

.security_top_box,
.brand_top_box {
  min-height: 31px;
  width: auto;
  padding: 10px;
  border-top: 2px solid #6b6666;
  border-right: 2px solid #6b6666;
  border-top-right-radius: 40px;
}

.security_top_image {
  background-image: url("../images/image-gallery/security_health.svg");
  display: block;
  width: 32px;
  height: 31px;
  margin-left: 24%;
}

.brand_top_image {
  background-image: url("../images/image-gallery/brand_security.svg");
  display: block;
  width: 32px;
  height: 31px;
  margin-left: 24%;
}

.fa-domains-dns {
  background-image: url("../images/image-gallery/domain_health.svg");
  width: 24px;
  height: 24px;
}

.fa-known-vulnerabilities {
  background-image: url("../images/image-gallery/vulnerabilities.svg");
  width: 17px;
  height: 24px;
}

.fa-ssl-certificate {
  background-image: url("../images/image-gallery/ssl_certificate.svg");
  width: 32px;
  height: 34px;
}

.fa-web-tech {
  background-image: url("../images/image-gallery/webtechnologies.svg");
  width: 24px;
  height: 24px;
}

.fa-mobile-apps {
  background-image: url("../images/image-gallery/Icon.svg");
  width: 24px;
  height: 24px;
}

.fa-look-alike {
  background-image: url("../images/image-gallery/Icon_1.svg");
  width: 24px;
  height: 24px;
}

.fa-social-media {
  background-image: url("../images/image-gallery/Icon_Selected.svg");
  width: 32px;
  height: 32px;
}

.fa-breach-detection {
  background-image: url("../images/image-gallery/breach.svg");
  width: 32px;
  height: 27px;
  margin-left: 22%;
  margin-top: 12px;
}

.header_active {
  font-size: 30px;
  /* border-radius: 100%; */
  margin: 13px 15px;
}

.loaderClass {
  justify-content: center;
  align-items: center;
  height: 600px;
}

.fa-logout {
  background-image: url("../images/image-gallery/signout.svg");
  width: 35px;
  height: 35px;
  float: right;
  margin-top: 30px;
}

.headerBox.home_icon {
  background-color: #2b303d;
  height: 110px;
  width: 7% !important;
  transition: height 0.6s ease, width 0.6s ease;
}

.headerBox {
  background-color: #141821;
  height: 0px;
  width: 7% !important;
}

.sidemenu_item.sidemenu_top_category,
.sidemenu_item.sidemenu_bottom_category {
  text-align: left !important;
  font-size: 11px;
  font-weight: 600;
  padding: 10px;
}

.sidemenu_bar {
  width: 120px;
}

.sidemenu_item {
  position: relative;
}

.headerBox {
  position: relative;
}

.sidemenu_item_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 0px;
  height: 0px;
}

.sidemenu_item_overlay.active {
  opacity: 0.5;
  width: 100%;
  height: 100%;
  background-color: #2b303d;
  transition: all 0.5s ease;
}

.menu-item-box {
  width: 60px;
  height: 56px;
  display: block;
  margin-left: 22%;
  margin-top: 10px;
}

.menu-item-box.active {
  width: 60px;
  height: 56px;
  display: block;
  background: #000000;
  border-radius: 100%;
}

.menu-item-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.menu-item-container.active {
  flex-direction: column-reverse;
  background-color: #2b303d;
  transition: all 0.8s ease;
}

.menu-item-container.active .menu-item-box {
  background: #000000;
  border-radius: 100%;
}

.main-container {
  width: 100px;
}

.main-container.active {
  width: 100%;
  transition: width 0.6 ease-in;
}

.table-responsive.table-box {
  margin-top: 0px !important;
  padding-right: 0px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.table-responsive.table-box::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-responsive.table-box {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.table.table-custom {
  border-collapse: collapse !important;
}

#main-content .col-sm-9.gradinet-effect{
    padding-right: 0px;
}

ul.pagination.pagination-bar{
    margin: 10px;
    float: right;
}

.data-container{
    opacity: 0 !important;
}

.data-container.active{
    opacity: 1 !important;
    transition: all 0.4s ease-out;
}

.alert_screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 25px;
  text-align: center;
}