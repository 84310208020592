body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.larger-text {
            font-size: 18px;
            line-height: 1.5;
}

/* .fade-enter{
  opacity: 0;
}
.fade-exit{
  opacity: 1;
}
.fade-enter-active{
  opacity: 1;
}
.fade-exit-active{
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active{
  transition: opacity 500ms;
}
 */

select option {
  background: #141414 !important;
}


.span_total {
  font-size: 18px;
  color: #191d28;
}

.summaryGraph text {
  font-size: 22px !important;
  fill: #ffffff;
}

.nav-table-tab {
  color: #fff !important;
  font-size: 14px !important;
  cursor: pointer !important;
  width: 220px !important;
  text-align: center !important;
}

.nav-table-tab-big {
  width: 300px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  color: #fff !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  border: 1px solid #fff;
}

.MuiSelect-select {
  border: 1px solid #fff !important;
}

.MuiList-root {
  background: #060606 !important;
  color: #fff !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  color: #fff !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #fff !important;
}

.css-1xcs70u-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #fff !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #191d28 !important;
  border-width: 1px !important;
}

.modal_mob {
  height: auto !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background-color: #191d28 !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-size: 12px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  max-height: 12px;
  font-size: 12px;
  min-height: 14px;
}

.css-1domaf0 {
  width: 140px !important;
}

.MuiSelect-select {
  padding: 9px !important;
}


/* .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  background: #191d28;
  color: #141414 !important;
} */

.MuiSelect-select {
  background: #191d28 !important;
  color: #fff !important;
}


.css-qiwgdb.MuiSelect-select {
  background: #191d28;
  color: #fff !important;
  padding: 6px 7px !important;
  font-size: 13px;
}

.css-r8u8y9 {
  background: #141414 !important;
  color: #fff !important;
}

.MuiMenu-list {
  display: flex;
  flex-direction: column;
  width: 140px;
}

.modal {
  z-index: 30000 !important;
}

.modal-dialog {
  margin: 8.75rem auto;
}

.MuiButtonBase-root {
  padding: 6px 12px 6px 14px !important;
  width: 100%;
  justify-content: unset !important;
}

.Mui-selected {
  background-color: rgb(25 118 210 / 31%) !important;
  font-size: 13px !important;
}

.Unsnooze {
  color: #000 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  letter-spacing: 0.2px !important;
}

.checkUncheck {
  width: 17px;
  height: 17px;
  margin-top: 3px
}

.nav-link.active {
  background-color: #141821 !important;
}

/* loader */


body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #b5b8bb;
  outline: 2px solid #b5b8bb;
}

.css-1636szt {
  color: #fff !important;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: #fff !important;
}

.css-bpeome-MuiSvgIcon-root-MuiSelect-icon {
  color: #fff !important;
}


.client_card .body {
  background-color: #2d3240 !important;
}

#map {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  flex: 1;
  /* This is a test */
}

#map-container {
  /* This is a test */
  display: flex;
  /* This is a test */
  width: 100vw;
  /* This is a test */
  height: 100vh;
  /* This is a test */
}

.leaflet-container {
  background: black;
}

.circle {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  margin: 0px;
}


.box-active:hover {
  border: 1px solid transparent;
}

.box-active:hover:after {
  background-color: #3fa9f5;
  transition: 0s ease;
}

.box-active::after {
  content: '\f00c' !important;
  font-family: 'FontAwesome';
  font-size: 24px;
  position: absolute !important;
  bottom: -50px;
  left: 50% !important;
  width: 30px !important;
  height: 30px !important;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3fa9f5 !important;
}

.form-control:focus {
  color: #fff !important;
}

.form-control {
  color: #fff !important;
}

#clientModal .modal-body {
  height: auto !important;
}

.btn-primary {
  background-color: #3fa9f5 !important;
  border-color: #3fa9f5 !important;
}

.tab-content>.active {
  position: relative;
  /* height: 100%; */
}

.tab-chat {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
}


.tab-chat input {
  position: sticky;
  width: 100%;
  bottom: 25px;
  border-radius: 5px;
  outline: none;
  border: none;
  padding: 2px 10px;
  font-size: 12px;
}

a:focus {
  color: #fff !important;
}

a:hover{
  color: #fff !important;
}

#bubble-container{
  background-color: #222733 !important;
}