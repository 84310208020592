@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css?family=Poiret+One');

/* body, html {
	height: 100%;
	background-repeat: no-repeat; 
	background:black;
	position: relative;
} */
#login-box {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
    width: 440px;
    margin: 0 auto;
    border: 1px solid black;
    background: rgba(48, 46, 45, 1);
    min-height: 250px;
    padding: 20px;
    z-index: 9999;
}

#login-box .logo .logo-caption {
    font-family: 'Poiret One', cursive;
    color: white;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 20px;
}

#login-box .logo .tweak {
    color: #ff5252;
}

#login-box .form-auth-small {
    padding-top: 30px;
}

#login-box .form-auth-small input {
    border-radius: 0px;
    background: #626060;
    border: 0px;
    color: white !important;
    font-family: 'Nunito', sans-serif;
}

#login-box .form-auth-small input:focus {
    box-shadow: none;
}

#login-box .form-auth-small input:focus-visible {
    border: none;
    outline: none;
}

/* #login-box input:-webkit-autofill,
#login-box input:-webkit-autofill:hover, 
#login-box input:-webkit-autofill:focus, 
#login-box input:-webkit-autofill:active{
	-webkit-text-fill-color: #626060 !important;
	transition: background-color 5000s ease-in-out 0s;
} */

#login-box button.btn-custom {
    border-radius: 2px;
    margin-top: 8px;
    background: #ff5252;
    border-color: rgba(48, 46, 45, 1);
    color: white;
    font-family: 'Nunito', sans-serif;
}

#login-box button.btn-custom:hover {
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    background: rgba(48, 46, 45, 1);
    border-color: #ff5252;
}

#particles-js {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    position: fixed;
    top: 0px;
    z-index: 1;
}

#login-box h1 {
    font-size: 40px;
}


#login-box #email,
#login-box #passwords {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

#login-box #password {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

#login-box .btn {
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    border: 1px solid transparent;
}

#login-box ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1;
    /* Firefox */
}

.dashboardFirstHeading {
    color: #ffffff;
}

.dashboardSecondHeading {
    float: right;
    color: #ffffff;
}

.fa-file-text:before,
.fa-certificate:before,
.fa-exclamation:before {
    color: #17c2d7;
}


.login:before {
    content: '';
    position: absolute;
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px;
    z-index: -1;
    background: rgba(0, 0, 0, 0.08);
    border-radius: 4px;
}

.login {
    position: relative;
    margin: 30px auto;
    padding: 0px 20px 20px 20px;
    width: 420px;
    height: 440px;
    background: white;
    border-radius: 3px;
    -webkit-box-shadow: 0 0 200px rgb(255 255 255 / 30%), 0 1px 2px rgb(0 0 0 / 30%);
    box-shadow: 0 0 200px rgb(255 255 255 / 30%), 0 1px 2px rgb(0 0 0 / 30%);
}

.login .form-control-new {
    width: 360px;
    margin: 5px;
    padding: 0 10px;
    height: 40px;
    color: #ffffff;
    background: none;
    border: none;
    border-color: #7e7e7e;
    border-radius: 2px;
    outline: 1px solid #7e7e7e;
    -moz-outline-radius: 3px;
    -webkit-box-shadow: inset 0 1px 3px rgb(0 0 0 / 12%);
    box-shadow: inset 0 1px 3px rgb(0 0 0 / 12%);
}

.login h1 {
    margin: -20px -20px 21px;
    line-height: 40px;
    font-size: 15px;
    /* font-weight: bold; */
    /* color: #555 !important; */
    color: #ffffff !important;
    text-align: center;
    text-shadow: 0 1px white;
    /* background: #f3f3f3; */
    border-bottom: none;
    border-radius: 3px 3px 0 0;
    /* background-image: -webkit-linear-gradient(top, whiteffd, #eef2f5);
    background-image: -moz-linear-gradient(top, whiteffd, #eef2f5);
    background-image: -o-linear-gradient(top, whiteffd, #eef2f5);
    background-image: linear-gradient(to bottom, whiteffd, #eef2f5); */
    -webkit-box-shadow: 0 1px #4d4e4f;
    box-shadow: 0 1px #4d4e4f;
}

.login p.remember_me label {
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
}

.login p.remember_me input {
    position: relative;
    bottom: 1px;
    margin-right: 4px;
    vertical-align: middle;
}

.login p.remember_me {
    /* float: left; */
    line-height: 31px;
}

.login p {
    margin: 20px 0 20px 10px;
}

/* 
.login p.submit {
    text-align: right;
} */

.login .loginButton {
    padding: 0 22px;
    height: 32px;
    margin-left: 12px;
    font-size: 14px;
    color: #ffffff;
    text-shadow: 0 1px #e3f1f1;
    background: #181c26;
    border: 1px solid;
    border-color: #b4ccce #b3c0c8 #9eb9c2;
    border-radius: 16px;
    box-sizing: content-box;
    /* padding: 0 22px;
    height: 32px;
	margin-left: 12px;
    font-size: 14px;
    font-weight: bold;
    color: #527881;
    text-shadow: 0 1px #e3f1f1;
    background: #cde5ef;
    border: 1px solid;
    border-color: #b4ccce #b3c0c8 #9eb9c2;
    border-radius: 16px;
    outline: 0;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    background-image: -webkit-linear-gradient(top, #edf5f8, #cde5ef);
    background-image: -moz-linear-gradient(top, #edf5f8, #cde5ef);
    background-image: -o-linear-gradient(top, #edf5f8, #cde5ef);
    background-image: linear-gradient(to bottom, #edf5f8, #cde5ef);
    -webkit-box-shadow: inset 0 1px white, 0 1px 2px rgb(0 0 0 / 15%);
    box-shadow: inset 0 1px white, 0 1px 2px rgb(0 0 0 / 15%); */
}

.login .loginFooter {
    display: flex;
}

.login .loginGoogle {
    height: 32px;
    width: 360px;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    text-shadow: 0 1px #e3f1f1;
    background: none;
    /* background: #ffffff; */
    border: 1px solid;
    border-color: #7e7e7e;
    outline: 0;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.login-help {
    margin: 20px 0;
    font-size: 14px;
    color: white;
    text-align: center;
    text-shadow: 0 1px #2a85a1;
}

.login-help a {
    color: #cce7fa;
    text-decoration: none;
}

.login .form-control-feedback {
    position: absolute;
    top: 19px;
    right: 15px;
    width: 34px;
    text-align: center;
    height: 34px;
    z-index: 2;
    cursor: pointer;
}

.login .has-feedback {
    position: relative;
}

.tox-notifications-container {
    display: none;
}

.arrowRed {
    color: #ff0000;
    font-size: 20px !important;
    margin-right: 4px;
}

.arrowGreen {
    color: #00ff1f;
    font-size: 20px !important;
    margin-right: 4px;
}

.securityHeader.first {
    margin-left: 1%;
}

.securityHeader.last {
    margin-right: 0%;
    width: 18% !important;

}

.securityHeader .card {
    margin-bottom: 0px;
    background-color: #4c4c4c;
}

.securityHeader {
    width: 19%;
    margin-right: 1%;
}

.table,
.textColor {
    color: #ffffff;
}

.textColor:focus {
    color: #ffffff !important;
}

.surface-table table tr td {
    padding: 4px;
}

.apexcharts-canvas {
    color: #000000;
}

.color-white {
    color: #ffffff !important;
    font-size: 11px;
}


.modal {
    overflow-y: auto;
}

.modal::-webkit-scrollbar {
    width: 0.5em;
}

.modal::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

.modal::-webkit-scrollbar-thumb {
    background-color: #b5b8bb;
    outline: 2px solid #b5b8bb;
}

.modal .modal-header button {
    background: transparent;
    box-shadow: none;
    border: none;
    color: #fff;
    font-size: 18px;
}

.login {
    height: 390px;
}

.form-control::placeholder {
    /* font-size: 10px; */
    color: #fff !important;
}


.colorBtnAdd {
    background-color: #ffcdcde6 !important;
    border: blanchedalmond;
}

.label_class {
    margin: 0px 5px;
}

.label_class input {
    margin: 0px 4px;
    cursor: pointer;
}

.modal-title {
    color: #fff;
}

.modal-body.pricing_page {
    padding: 0px;
}

.modal-dialog .modal-content {
    background-image: linear-gradient(0deg, #2f3443 0%, #2a2f3d 33.08%, #1e222e 74.21%, #141821 100%) !important;
}

.modal-body .card .body {
    box-shadow: none;
    border: none;
}

.form-control:disabled {
    background-color: #2e3342;
}

.MuiAutocomplete-popper {
    z-index: 100000 !important;
    left: 5% !important;
}

.dashboard_remediation_report {
    left: -1px !important;
    top: 33%;
    position: fixed;
    z-index: 100;
    width: 86px;
    text-align: center;
}

.dashboard_remediation_report {
    left: -1px !important;
    top: 33%;
    position: fixed;
    z-index: 100;
    width: 86px;
    text-align: center;
}

/* .modal {
    top: 12%;
} */

.checkUncheck {
    width: 17px;
    height: 17px;
    margin-top: 3px
}
  
.typeHereInput{
    padding: 3px 9px;
    font-size: 9px;
    border: 1px solid #5f5e5e;
    border-radius: 0px;
    background: #2f3443;
}

.openTask{
    padding: 3px 5px;
    cursor: pointer;
    border-radius: 5px;
    border: 2px solid #3fa9f5;
    font-size: 9px;
    background: #141821;
    color: #3fa9f5;
}

input[type="checkbox"].checkUncheck {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #858484;
    width: 16px;
    height: 16px;
    border: 1px solid #858484;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
}

/* Style for checked state */
input[type="checkbox"].checkUncheck:checked {
    background-color: #858484;
}

/* Add checkmark */
input[type="checkbox"].checkUncheck:checked:after {
    content: '✓';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
}